import {
  Badge,
  Button,
  HStack,
  Image,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import AuthLayout from './layout'
import { Formik, FormikConsumer } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../redux/slices/userSlice'
import { FaChevronRight } from 'react-icons/fa'
import { FaArrowRight, FaGoogle } from 'react-icons/fa6'
import SharedComponents from 'shared-components'
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { collection, query, where } from 'firebase/firestore'
import * as FirebaseFunctions from 'firebase/functions'

export default () => {
  const dispatch = useDispatch()
  const firebaseContext = SharedComponents.Firebase.context()
  const [isBusy, setIsBusy] = useState()
  const [adminError, setAdminError] = useState('')
  const initState = {
    email: '',
    name: '',
    password: '',
    rePassword: ''
  }
  const [customerState, setCustomerState] = useState()
  const [pageState, setPageState] = useState(initState)
  const [formStep, setFormStep] = useState(0)
  const [isMobile] = useMediaQuery('(max-width: 800px')

  // useEffect(() => {
  //   fetchPlans()
  // }, [])

  function updatePageState (key, value) {
    SharedComponents.FunctionExtensions.updateNestedState({
      setStateFunction: setPageState,
      path: key,
      value
    })
  }

  async function findCustomer () {
    setIsBusy(true)
    const _customer = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'findCustomer'
    )
    try {
      const result = await _customer({
        email: pageState.email
      })
      // dispatch(setSubscription({ ...result.data, isBusy: false }))
      if (!result.data) {
        console.log('About to create a new customer on Stripe')
        setFormStep(2)
      } else {
        console.log('This is an existing customer:', result.data)
        setFormStep(1)
        const customerData = { ...result.data, customerId: result.data.id }
        setCustomerState(customerData)
      }
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
      // dispatch(setSubscription({ ...data, isBusy: false }))
    }
    setIsBusy(false)
  }

  async function createCustomer () {
    setIsBusy(true)
    const _customer = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'createCustomer'
    )
    try {
      const customerResult = await _customer({
        customer: {
          name: pageState.name,
          email: pageState.email
        }
      })
      console.log('Created customer on Stripe:', customerResult.data)
      if (customerResult.data) {
        try {
          const signUpResult = await createUserWithEmailAndPassword(
            firebaseContext.auth,
            pageState.email,
            pageState.password
          )
          const { user } = signUpResult
          console.log('Created user on the platform with ID:', user.uid)
          dispatch(
            setUser({
              ...customerResult.data,
              id: user.uid,
              customerId: customerResult.data.id
            })
          )
        } catch (ex) {
          console.warn('Could not create user on the platform', ex)
        }
      }

      // setFormStep(3)
    } catch (ex) {
      console.warn('Could not create customer initially...:', ex)
      setIsBusy(false)
    }
  }

  async function performLogin () {
    try {
      setIsBusy(true)
      const loginResult = await signInWithEmailAndPassword(
        firebaseContext.auth,
        pageState.email,
        pageState.password
      )
      const { user } = loginResult
      console.log('User logged in successfully.', {
        ...customerState,
        id: user.uid
      })
      dispatch(
        setUser({ ...customerState, id: user.uid, pwd: pageState.password })
      )
    } catch (ex) {
      console.warn('Failed to log in with credentials. Try again.', ex)
    }
    setIsBusy(false)
  }

  function isFormValid () {
    const match = SharedComponents.FunctionExtensions.matchObjects(
      initState,
      pageState
    )
    const isEmpty = SharedComponents.FunctionExtensions.hasEmptyValue(pageState)

    return !match && !isEmpty && pageState.password === pageState.rePassword
  }

  return (
    <AuthLayout>
      <Tabs index={formStep}>
        <TabList display={'none'}>
          <Tab>Init Form</Tab>
          <Tab>Password Form</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p='0'>
            <VStack
              w='full'
              spacing={'3'}
              align={isMobile ? 'center' : 'flex-start'}
              // overflowY={'scroll'}
            >
              <Text fontSize={'20px'} fontWeight={'600'}>
                Enter Your Email To Start
              </Text>
              <Input
                placeholder='Your Email'
                border='0'
                color='white'
                bg='#3c4747'
                size='lg'
                px='5'
                value={pageState.email}
                onChange={e => updatePageState('email', e.target.value)}
              />
              <Button
                size={'md'}
                colorScheme='green'
                w={isMobile ? 'full' : 'auto'}
                rightIcon={<FaArrowRight />}
                _hover={{
                  bg: 'black',
                  color: 'white'
                }}
                onClick={findCustomer}
                isLoading={isBusy}
              >
                Continue
              </Button>
            </VStack>
          </TabPanel>
          <TabPanel p='0'>
            <VStack
              w='full'
              spacing={'3'}
              align={isMobile ? 'center' : 'flex-start'}
              overflow={'scroll'}
            >
              <Text fontSize={'20px'} fontWeight={'600'}>
                Log in to your account
              </Text>
              <Input
                placeholder='Your Email'
                border='0'
                color='white'
                bg='#3c4747'
                size='lg'
                px='5'
                value={pageState.email}
                onChange={e => updatePageState('email', e.target.value)}
              />
              <Input
                placeholder='Password'
                border='0'
                color='white'
                bg='#3c4747'
                size='lg'
                px='5'
                value={pageState.password}
                type='password'
                onChange={e => updatePageState('password', e.target.value)}
              />
              <Button
                size={'md'}
                colorScheme='green'
                w={isMobile ? 'full' : 'auto'}
                rightIcon={<FaArrowRight />}
                _hover={{
                  bg: 'black',
                  color: 'white'
                }}
                onClick={performLogin}
                isLoading={isBusy}
              >
                Log In
              </Button>
            </VStack>
          </TabPanel>
          <TabPanel p='0'>
            <VStack
              w='full'
              spacing={'3'}
              align={isMobile ? 'center' : 'flex-start'}
              overflow={'scroll'}
            >
              <Text fontSize={'20px'} fontWeight={'600'}>
                Create your account
              </Text>
              <Input
                placeholder='Your Name'
                border='0'
                color='white'
                bg='#3c4747'
                size='lg'
                px='5'
                value={pageState.name}
                onChange={e => updatePageState('name', e.target.value)}
              />
              <Input
                placeholder='Password'
                border='0'
                color='white'
                bg='#3c4747'
                size='lg'
                px='5'
                value={pageState.password}
                type='password'
                onChange={e => updatePageState('password', e.target.value)}
              />
              <Input
                placeholder='Re-enter Password'
                border='0'
                color='white'
                bg='#3c4747'
                size='lg'
                px='5'
                type='password'
                value={pageState.rePassword}
                onChange={e => updatePageState('rePassword', e.target.value)}
              />
              <Button
                size={'md'}
                colorScheme='green'
                w={isMobile ? 'full' : 'auto'}
                rightIcon={<FaArrowRight />}
                _hover={{
                  bg: 'black',
                  color: 'white'
                }}
                isDisabled={!isFormValid()}
                onClick={createCustomer}
                isLoading={isBusy}
              >
                Start Listening
              </Button>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AuthLayout>
  )
}
