import { HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import AppRoutes, { extractNestedRoutes } from '../../routes'

export const AdditionalHeader = ({ children }) => {
  return <HStack w='max-content'>{children}</HStack>
}

export default ({ children, containerProps = {} }) => {
  const location = useLocation()
  const _additionalHeader =
    children &&
    React.Children.toArray(children).find(
      child => React.isValidElement(child) && child.type === AdditionalHeader
    )
  const remainingChild =
    children &&
    React.Children.toArray(children).filter(
      child => child.type !== AdditionalHeader
    )

  function pageTitle () {
    const path = location.pathname
    const _routes = extractNestedRoutes(AppRoutes.MainApp)
    return _routes.find(x => x.path === path).pageTitle
  }

  return (
    <VStack
      w='full'
      minH='full'
      h='max-content'
      align={'flex-start'}
      color={'white'}
      {...containerProps}
    >
      <HStack w='full' justify={'space-between'} wrap={'nowrap'}>
        <Text fontSize={'26px'} fontWeight={'600'} w='max-content' mb='2'>
          {pageTitle()}
        </Text>
        {_additionalHeader}
      </HStack>
      {remainingChild}
    </VStack>
  )
}
