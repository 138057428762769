import Home from './home'
import Profile from './profile'
import PaymentInfo from './paymentInfo'
import TicketMaster from './ticketMaster'

export default {
  Home,
  Profile,
  PaymentInfo,
  TicketMaster
}
