import {
  Badge,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import PageLayout from '../pageLayout'
import { useEffect, useState } from 'react'
import SharedComponents from 'shared-components'
import * as FirebaseFunctions from 'firebase/functions'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineBank } from 'react-icons/ai'
import { CiCreditCard2 } from 'react-icons/ci'
import moment from 'moment'
import { RxDotsHorizontal } from 'react-icons/rx'
import {
  setAllPaymentMethods,
  setDefaultPaymentMethod,
  setPaymentMethod
} from '../../../redux/slices/paymentMethodSlice'
import { FaPlus } from 'react-icons/fa6'
import { setUserProps } from '../../../redux/slices/userSlice'
import {
  FaArrowRight,
  FaExternalLinkAlt,
  FaStripe,
  FaStripeS
} from 'react-icons/fa'

export default () => {
  const firebaseContext = SharedComponents.Firebase.context()
  const user = useSelector(state => state.user)
  const adminProfile = useSelector(state => state.app.adminProfile)
  const [isBusy, setIsBusy] = useState(false)
  const toast = useToast()

  async function test () {
    setIsBusy(true)
    const billingSession = FirebaseFunctions.httpsCallable(
      firebaseContext.functions,
      'createCustomerBillingSession'
    )

    try {
      const result = await billingSession({
        customerId: user.customerId,
        stripeAccountId: adminProfile.stripeAccountId,
        returnUrl: window.location.href
      })

      if (result.data) {
        // console.log('Data is:', result.data)
        window.open(result.data, '_self')
      }
    } catch (ex) {
      setIsBusy(false)
      toast({
        title: 'Something went wrong',
        description: "We couldn't generate a session for you. Try again later",
        isClosable: true,
        status: 'error'
      })
      console.log('Cloud function error:', ex)
    }
  }

  return (
    <PageLayout>
      <Button
        size='sm'
        rightIcon={<FaArrowRight />}
        colorScheme='gray'
        loadingText='Please wait...'
        onClick={test}
        isLoading={isBusy}
      >
        Mange Billing On Stripe
      </Button>
    </PageLayout>
  )
}
