import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  current: null,
  soundObject: null
}

export const musicSlice = createSlice({
  name: 'music',
  initialState,
  reducers: {
    setAllMusic: (state, action) => {
      return { ...state, list: action.payload }
    },
    setCurrentMusic: (state, action) => {
      return { ...state, current: action.payload }
    },
    setSoundObject: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          soundObject: { ...state.soundObject, ...action.payload }
        }
      } else {
        return { ...state, soundObject: null }
      }
    },
    setMusic: (state, action) => {
      if (state.list && state.list.length > 0) {
        const spread = [...state.list]
        const _music = spread.find(x => x.id === action.payload.id)

        // Found existing data
        console.log('Found existing music in state:', _music)
        if (_music) {
          const _musicIndex = spread.findIndex(x => x.id === action.payload.id)
          spread[_musicIndex] = { ..._music, ...action.payload }
          state.list = spread
        }
      } else {
        return [action.payload]
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAllMusic, setCurrentMusic, setSoundObject, setMusic } =
  musicSlice.actions

export default musicSlice.reducer
