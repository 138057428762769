import { Box, Button, HStack, Skeleton, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import { MdArrowOutward } from 'react-icons/md'
import DashboardData from '../../../assets/sampleData/dashboardData.json'
import SharedComponents from 'shared-components'
import { GoPlay } from 'react-icons/go'
import { AiFillPlayCircle } from 'react-icons/ai'
import { IoPlay } from 'react-icons/io5'
import { Howl, Howler } from 'howler'
import { AnimatePresence, motion } from 'framer-motion'

const MusicCard = ({ key, imageUrl, name, type, onClick = () => {} }) => {
  const [isMobile] = useMediaQuery('(max-width: 800px')

  return (
    <VStack
      role='group'
      _hover={{
        transform: 'scale(1.03)'
      }}
      transition={'all 300ms'}
      pos='relative'
      justify={'center'}
      cursor={'pointer'}
      onClick={onClick}
    >
      <VStack
        transition={'all 200ms'}
        pos='absolute'
        top='10px'
        h='150px'
        justify={'center'}
        opacity={'0'}
        _groupHover={{
          opacity: '1',
          top: '0px'
        }}
        zIndex={'99'}
      >
        <VStack
          borderRadius={'full'}
          h={type === 'music' ? '55px' : 'max-content'}
          w={type === 'music' ? '55px' : 'max-content'}
          boxShadow={'0px 10px 24px 0px rgba(0,0,0,1)'}
          justify={'center'}
          bg='white'
          color={'black'}
          px={type === 'album' && '3'}
          py={type === 'album' && '1'}
        >
          {type === 'music' && <IoPlay size='20' />}
          {type === 'album' && (
            <Text fontSize={'14px'} fontWeight={'600'}>
              Show Songs
            </Text>
          )}
        </VStack>
      </VStack>
      {type === 'album' && (
        <>
          <Box
            pos='absolute'
            transition={'all 200ms'}
            // p='0.5'
            borderRadius={'md'}
            top='0'
            transformOrigin={'bottom center'}
            transform={'rotate(0deg) translate(0px, 0px)'}
            zIndex={0}
            opacity={'0'}
            _groupHover={{
              bg: 'white',
              transform: 'rotate(-5deg) translate(0px, -5px)',
              opacity: 1
            }}
          >
            <SharedComponents.AppComponent.LazyImage
              h='150px'
              w='140px'
              bg='gray'
              borderRadius={'md'}
              objectFit={'cover'}
              src={imageUrl}
            />
          </Box>
          <Box
            pos='absolute'
            transition={'all 200ms'}
            // p='0.5'
            borderRadius={'md'}
            top='0px'
            transformOrigin={'bottom center'}
            zIndex={0}
            opacity={'0'}
            _groupHover={{
              transform: 'rotate(5deg) translate(0px, -5px)',
              opacity: '1'
            }}
          >
            <SharedComponents.AppComponent.LazyImage
              h='150px'
              w='140px'
              bg='gray'
              borderRadius={'md'}
              objectFit={'cover'}
              src={imageUrl}
            />
          </Box>
        </>
      )}
      <Box
        transition={'all 200ms'}
        bg='#ffffff01'
        p='0.5'
        borderRadius={'md'}
        zIndex={'1'}
        boxShadow={'0px -3px 15px 0px rgba(0,0,0,0)'}
        _groupHover={{
          bg: 'white',
          boxShadow: '0px -3px 15px 0px rgba(0,0,0,0.5)'
        }}
        w={isMobile ? 'full' : 'auto'}
      >
        <SharedComponents.AppComponent.LazyImage
          h='150px'
          w={isMobile ? 'full' : '140px'}
          bg='gray'
          borderRadius={'md'}
          objectFit={'cover'}
          src={imageUrl}
        />
      </Box>
      <Text
        transition={'all 200ms'}
        fontSize={isMobile ? '18px' : '14px'}
        fontWeight={'600'}
        w='full'
        textAlign={'center'}
      >
        {name}
      </Text>
    </VStack>
  )
}

const MusicSkeleton = () => {
  return (
    <VStack
      role='group'
      _hover={{
        transform: 'scale(1.03)'
      }}
      transition={'all 300ms'}
      pos='relative'
      justify={'center'}
    >
      <Box
        transition={'all 200ms'}
        bg='#ffffff01'
        p='0.5'
        borderRadius={'md'}
        zIndex={'1'}
        boxShadow={'0px -3px 15px 0px rgba(0,0,0,0)'}
        _groupHover={{
          bg: 'white',
          boxShadow: '0px -3px 15px 0px rgba(0,0,0,0.5)'
        }}
      >
        <SharedComponents.AppComponent.LazyImage
          h='150px'
          w='140px'
          bg='gray'
          borderRadius={'md'}
          objectFit={'cover'}
          src={''}
        />
      </Box>
      <Skeleton h='17px' />
    </VStack>
  )
}

export const HomeComponents = {
  MusicCard,
  MusicSkeleton
}
