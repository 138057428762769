import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PageLayout from '../pageLayout'
import {
  Box,
  HStack,
  Spinner,
  Text,
  useMediaQuery,
  VStack,
  Tooltip
} from '@chakra-ui/react'
import SharedComponents from 'shared-components'
import moment from 'moment'

export default () => {
  const [events, setEvents] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const TicketMaster_API_KEY = 'EdUt9Pa8LrpEsnYC5XbpRArZkD1eUgWD'
  const adminProfile = useSelector(state => state.app.adminProfile)
  const ticketMasterId = useSelector(
    state => state.app.adminProfile?.ticketMasterId
  )
  const containerRef = useRef()
  const [isMobile] = useMediaQuery('(max-width: 600px')

  useEffect(() => {
    getEvents()
  }, [])

  async function getEvents () {
    setIsFetching(true)
    try {
      const result = await axios.get(
        `https://app.ticketmaster.com/discovery/v2/events?apikey=${TicketMaster_API_KEY}&attractionId=${ticketMasterId}`
      )
      setEvents(result.data?._embedded?.events)
      console.log('Found events:', result.data?._embedded?.events)
    } catch (ex) {
      console.log('Something went wrong when fetching events')
    }
    setIsFetching(false)
  }

  function getBlockSize () {
    if (containerRef.current) {
      //   const divideBy = isMobile ? 1 : 4.5
      return isMobile
        ? `${containerRef.current.offsetWidth}px`
        : `${containerRef.current.offsetWidth / 4.5}px`
    }
  }
  return (
    <PageLayout>
      {isFetching && <Spinner />}
      {!isFetching && !events && <Text>No upcoming events.</Text>}
      {!isFetching && events && (
        <HStack
          ref={containerRef}
          w='full'
          //   blockSize={getBlockSize()}
          flexWrap={'wrap'}
          spacing={'5'}
          rowGap={'10'}
        >
          {events.map(ev => (
            <EventItem
              key={`event_item_${ev.id}`}
              data={ev}
              w={getBlockSize()}
            />
          ))}
        </HStack>
      )}
    </PageLayout>
  )
}

const EventItem = ({ data, w }) => {
  const getImage = () => {
    return data.images?.find(x => x.ratio === '4_3').url
  }
  const getTimezone = () => {
    const _formatted = data.dates?.timezone.replace('_', ' ')
    return _formatted.split('/').pop()
  }

  function goToTicketMaster () {
    console.log('Openining:', data.url)
    window.open(data.url, '_blank')
  }
  return (
    <Tooltip
      hasArrow
      bg='white'
      placement='right'
      label='Get Tickets'
      color='black'
    >
      <VStack
        role='group'
        _hover={{
          transform: 'scale(1.03)'
        }}
        transition={'all 300ms'}
        pos='relative'
        justify={'center'}
        cursor={'pointer'}
        align={'flex-start'}
        w={w}
        onClick={goToTicketMaster}

        //   minW='200px'
        //   onClick={onClick}
      >
        <Box
          transition={'all 200ms'}
          bg='#ffffff01'
          p='0.5'
          borderRadius={'md'}
          zIndex={'1'}
          boxShadow={'0px -3px 15px 0px rgba(0,0,0,0)'}
          _groupHover={{
            bg: 'white',
            boxShadow: '0px -3px 15px 0px rgba(0,0,0,0.5)'
          }}
          w='full'
        >
          <SharedComponents.AppComponent.LazyImage
            h='150px'
            w='full'
            bg='gray'
            borderRadius={'md'}
            objectFit={'cover'}
            src={getImage()}
          />
        </Box>
        <VStack w='full' px='2' spacing={'0'} align={'flex-start'}>
          <Text transition={'all 200ms'} fontSize={'14px'} fontWeight={'600'}>
            {data.name}
          </Text>
          <Text transition={'all 200ms'} fontSize={'12px'}>
            Starts on:{' '}
            {moment(data.dates?.start?.dateTime).format('MMM Do, YYYY, h a')}
            <br />
            {getTimezone()} Time
          </Text>
        </VStack>
      </VStack>
    </Tooltip>
  )
}
