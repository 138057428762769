import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setAllSubscriptions: (state, action) => {
      return action.payload
    },
    setSubscription: (state, action) => {
      if (state && state.length > 0) {
        const spread = [...state]
        const _subscription = spread.find(x => x.id === action.payload.id)

        // Found existing data
        if (_subscription) {
          const _subscriptionIndex = spread.findIndex(x => x.id === action.payload.id)
          spread[_subscriptionIndex] = { ..._subscription, ...action.payload }
          return spread
        } else {
          spread.unshift(action.payload)
          return spread
        }
      } else {
        return [action.payload]
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAllSubscriptions, setSubscription } = subscriptionSlice.actions

export default subscriptionSlice.reducer
