import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const paymentMethodSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setAllPaymentMethods: (state, action) => {
      return action.payload
    },
    setPaymentMethod: (state, action) => {
      if (state && state.length > 0) {
        const spread = [...state]
        const _paymentMethod = spread.find(x => x.id === action.payload.id)

        // Found existing data
        if (_paymentMethod) {
          const _paymentMethodIndex = spread.findIndex(
            x => x.id === action.payload.id
          )
          spread[_paymentMethodIndex] = { ..._paymentMethod, ...action.payload }
          return spread
        }
      } else {
        return [action.payload]
      }
    },
    setDefaultPaymentMethod: (state, action) => {
      if (state && state.length > 0) {
        const spread = [...state].map(x => ({ ...x, isDefault: false }))
        const _paymentMethod = spread.find(x => x.id === action.payload)

        // Found existing data
        if (_paymentMethod) {
          const _paymentMethodIndex = spread.findIndex(
            x => x.id === action.payload
          )
          spread[_paymentMethodIndex] = { ..._paymentMethod, isDefault: true }
          return spread
        }
      } else {
        return [action.payload]
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAllPaymentMethods, setPaymentMethod, setDefaultPaymentMethod } =
  paymentMethodSlice.actions

export default paymentMethodSlice.reducer
