import { GoHome } from 'react-icons/go'
import LoginScreen from './screens/auth/login'
import AppPages from './screens/mainApp/pages'
import { CiCreditCard1 } from 'react-icons/ci'
import { GiTicket } from 'react-icons/gi'
import { BsMusicNote } from 'react-icons/bs'

const Routes = {
  Auth: {
    screens: {
      Login: {
        path: '/',
        element: <LoginScreen />
      }
    }
  },
  MainApp: {
    screens: {
      Home: {
        label: 'Home',
        icon: <BsMusicNote size='17' />,
        path: '/home',
        element: <AppPages.Home />,
        pageTitle: 'All Releases'
      },
      Events: {
        label: 'Events & Shows',
        icon: <GiTicket size='17' />,
        path: '/events',
        element: <AppPages.TicketMaster />,
        pageTitle: 'Upcoming events & shows'
      },
      PaymentInfo: {
        label: 'My Subscriptions',
        icon: <CiCreditCard1 size='17' />,
        path: '/payment-info',
        element: <AppPages.PaymentInfo />,
        pageTitle: 'Manage Your Billing'
      }
      // Monetization: {
      //   label: 'Monetization',
      //   pageTitle: 'Monetization Plans',
      //   icon: <MdOutlineAttachMoney size='17' />,
      //   path: '/monetization',
      //   element: <AppPages.Monetization />
      // },
      // Users: {
      //   label: 'My Listeners',
      //   icon: <FiUserCheck size='17' />,
      //   path: '/users',
      //   element: <AppPages.Users />,
      //   pageTitle: 'Manage users of platform'
      // }
      // Profile: {
      //   label: 'My Profile',
      //   icon: <LuUserCog2 size='17' />,
      //   path: '/profile',
      //   element: <AppPages.Profile />,
      //   pageTitle: 'Manage your profile'
      // }
    }
  }
}

export default Routes

export function extractNestedRoutes (routeObject) {
  if (routeObject.screens && Object.keys(routeObject.screens).length > 0) {
    const nested_routes = []
    Object.keys(routeObject.screens).map(key => {
      const _routeSingle = routeObject.screens[key]
      nested_routes.push({ ..._routeSingle })
    })
    return nested_routes
  }

  return routeObject
}
