import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SharedComponents from 'shared-components'

export default () => {
  const firebaseContext = SharedComponents.Firebase.context()
  const [isBusy, setIsBusy] = useState(false)
  const userState = useSelector(state => state.user)
  const adminProfile = useSelector(state => state.app.adminProfile)
  const appState = useSelector(state => state.app)

  async function getAllMusic () {
    setIsBusy(true)
    const _songList = await firebaseContext.getDocument({ path: `profile/${adminProfile.id}/songs` })
    const allSongs = await Promise.all(
      _songList?.map(async song => {
        const isPurchased = await firebaseContext.getDocument({
          path: `profile/${adminProfile.id}/songs/${song.id}/purchases/${userState.id}`
        })
        return {
          ...song,
          isPurchasedByUser: isPurchased ? true : false
        }
      })
    )
    setIsBusy(false)

    return allSongs
  }

  return { getAllMusic, isBusy }
}
