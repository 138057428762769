import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return {...state, ...action.payload}
    },
    setUserProps: (state, action) => {
      return { ...state, [action.payload.key]: action.payload.value }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUser, setUserProps } = userSlice.actions

export default userSlice.reducer
