import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { MdArrowOutward, MdCalendarMonth } from 'react-icons/md'
import { RangeDatepicker } from 'chakra-dayzed-datepicker'
import { useEffect, useRef, useState } from 'react'
import { HomeComponents } from './components'
import SharedComponents from 'shared-components'
import PageLayout, { AdditionalHeader } from '../pageLayout'
import { setAllSubscriptions } from '../../../redux/slices/subscriptionSlice'
import { setAllMusic, setCurrentMusic } from '../../../redux/slices/musicSlice'
import { useDispatch, useSelector } from 'react-redux'
import * as FirebaseFunctions from 'firebase/functions'
import { FaX } from 'react-icons/fa6'
import { AnimatePresence, isBrowser, motion } from 'framer-motion'
import useMusicHook from '../mymusic/useMusicHook'

export default () => {
  const { functions: contextFunctions } = SharedComponents.Firebase.context()
  const dispatch = useDispatch()
  const musicList = useSelector(state => state.music.list)
  const adminProfile = useSelector(state => state.app.adminProfile)
  const [filteredState, setFilteredState] = useState()
  const [currentAlbum, setCurrentAlbum] = useState()
  const { getAllMusic, isBusy: isFetchingMusic } = useMusicHook()

  useEffect(() => {
    const fetch = async () => {
      const songs = await getAllMusic()
      console.log('Songs are:', songs)

      const getSubscriptions = FirebaseFunctions.httpsCallable(
        contextFunctions,
        'getSubscriptions'
      )
      try {
        const result = await getSubscriptions({
          stripeAccountId: adminProfile.stripeAccountId
        })
        dispatch(setAllSubscriptions(result.data))
        dispatch(setAllMusic(songs))
      } catch (ex) {
        console.warn('CloudFunction error:', ex)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    defaultState()
  }, [musicList])

  function defaultState () {
    setFilteredState(
      musicList?.filter(x => x.status !== 'archived' && !x.albumId)
    )
  }

  async function onMusicClick (data) {
    if (data.type === 'album') {
      const _albumSongs = musicList?.filter(x => x.albumId === data.id)
      if (_albumSongs.length > 0) {
        setCurrentAlbum(data)
        setFilteredState(_albumSongs)
      }
    } else {
      dispatch(setCurrentMusic({ ...data, album: currentAlbum }))
    }
  }

  function clearFilter () {
    setCurrentAlbum(null)
    defaultState()
  }

  return (
    <PageLayout
      containerProps={{
        spacing: '0'
      }}
    >
      <AnimatePresence>
        {currentAlbum && (
          <motion.div
            key='filter_music_album'
            initial={{
              marginTop: '-30px',
              opacity: 0
            }}
            animate={{ marginTop: '0', opacity: 1 }}
            exit={{ marginTop: '-40px', opacity: 0 }}
          >
            <HStack
              transition={'all 300ms'}
              mb='2'
              bg='white'
              color='black'
              fontSize={'14px'}
              fontWeight={'600'}
              pl='3'
              borderRadius={'md'}
              overflow={'hidden'}
            >
              <Text>Showing songs of album: {currentAlbum?.name}</Text>
              <IconButton
                icon={<FaX />}
                size={'xs'}
                px='2'
                bg='white'
                _hover={{
                  bg: 'black',
                  color: 'white'
                }}
                h='30px'
                borderRadius={'0'}
                onClick={clearFilter}
              />
            </HStack>
          </motion.div>
        )}
        <Stack
          direction={['column', 'row']}
          key='music_container'
          w='full'
          flexWrap={'wrap'}
          spacing={'5'}
        >
          {isFetchingMusic &&
            musicList?.length <= 0 &&
            Array(3)
              .fill(0)
              .map((x, index) => (
                <motion.div
                  key={`music_skeleton_${index}`}
                  initial={{
                    top: '20px',
                    opacity: 0,
                    position: 'relative'
                  }}
                  animate={{
                    top: '0px',
                    opacity: 1
                  }}
                  exit={{
                    top: '-20px',
                    opacity: 0
                  }}
                >
                  <HomeComponents.MusicSkeleton />
                </motion.div>
              ))}
          {filteredState?.map(item => (
            <motion.div
              key={`home_screen_music_card_${item.id}`}
              initial={{
                top: '30px',
                position: 'relative'
              }}
              animate={{
                top: '0px'
              }}
            >
              <HomeComponents.MusicCard
                {...item}
                onClick={() => onMusicClick(item)}
              />
            </motion.div>
          ))}
        </Stack>
      </AnimatePresence>
    </PageLayout>
  )
}
