import logo from './logo.svg'
import './App.css'
import AppRoutes, { extractNestedRoutes } from './routes'
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom'
import { Button, VStack, Spinner, Text } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { setUser, setUserProps } from './redux/slices/userSlice'
import { useEffect, useState } from 'react'
import { AppLayout } from './screens/mainApp/appLayout'
import { setAppState } from './redux/slices/appSlice'
import axios from 'axios'
import SharedComponents from 'shared-components'

function AuthRoutes () {
  const _routes = extractNestedRoutes(AppRoutes.Auth)
  return createBrowserRouter(_routes)
}
function MainAppRoutes () {
  const _routes = extractNestedRoutes(AppRoutes.MainApp)
  return createBrowserRouter(_routes)
}

function App () {
  const userId = useSelector(state => state.user?.id)
  const adminAppId = useSelector(state => state.app.adminAppId)
  const [isFetchingAppId, setIsFetchingAppId] = useState(false)
  const dispatch = useDispatch()
  const firebaseContext = SharedComponents.Firebase.context()

  useEffect(() => {
    if (!adminAppId) findAppId()
    getIpInfo()
  }, [adminAppId])

  async function findAppId () {
    setIsFetchingAppId(true)
    const function_url = () => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_FUNCTIONS_URL_DEV
      } else return process.env.REACT_APP_FUNCTIONS_URL_PROD
    }
    try {
      const result = await axios.get(`${function_url()}getAppId`)
      const docRes = await firebaseContext.getDocument({
        path: `profile/${result.data.appId}`
      })
      dispatch(
        setAppState({
          key: 'adminProfile',
          value: docRes
        })
      )
      dispatch(
        setAppState({
          key: 'adminAppId',
          value: result.data.appId
        })
      )
      // dispatch(setCustomers(result.data))
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
    }
    setIsFetchingAppId(false)
  }

  async function getIpInfo () {
    try {
      const token = process.env.REACT_APP_IPINFO_TOKEN
      const response = await axios.get(`https://ipinfo.io/json?token=${token}`)
      if (response.data) {
        dispatch(setUserProps({ key: 'ipinfo', value: response.data }))
      }
    } catch (ex) {
      console.warn("Could not get user's IP Info")
    }
  }

  const RenderScreens = () => {
    if (isFetchingAppId) {
      return <Spinner size='md' />
    }
    if (adminAppId) {
      if (userId) {
        return (
          <Router>
            <Routes>
              <Route path='/' element={<AppLayout />}>
                <Route path='*' element={<NotFound />} />

                {MainAppRoutes().routes.map(_route => (
                  <Route key={`routeKey_${_route.path}`} {..._route} />
                ))}
              </Route>
            </Routes>
          </Router>
        )
      } else {
        return (
          <Router>
            <Routes>
              <Route path='*' element={<NotFound />} />
              {AuthRoutes().routes.map(_route => (
                <Route key={`routeKey_${_route.path}`} {..._route} />
              ))}
            </Routes>
          </Router>
        )
      }
    } else {
      return (
        <Router>
          <Routes>
            <Route
              path='/'
              element={
                <VStack w='full' h='full' k>
                  <Text fontSize={'xx-large'}>
                    Seems like this app is not configured right
                  </Text>
                </VStack>
              }
            ></Route>
          </Routes>
        </Router>
      )
    }
  }

  return (
    <VStack h='full' w='full'>
      <RenderScreens />
    </VStack>
  )
}

const NotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])
  return <></>
}

export default App
