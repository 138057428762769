import { useEffect } from 'react'
import Routes, { extractNestedRoutes } from '../../routes'
import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import Logo from '../../assets/images/logo-horizontal.png'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import SharedComponents from 'shared-components'
import { useDispatch, useSelector } from 'react-redux'
import { setAppState } from '../../redux/slices/appSlice'
import { setUser } from '../../redux/slices/userSlice'
import { MusicComponent } from './mymusic/components'
import { setCurrentMusic, setSoundObject } from '../../redux/slices/musicSlice'

export function AppLayout () {
  const { navbarExpanded } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const musicState = useSelector(state => state.music)
  const location = useLocation()
  const navigate = useNavigate()
  const adminProfile = useSelector(state => state.app.adminProfile)
  function MainAppRoutes () {
    const _routes = extractNestedRoutes(Routes.MainApp)
    return { routes: _routes }
  }
  const [isMobile] = useMediaQuery('(max-width: 800px')

  useEffect(() => {
    if (location.pathname === '/') {
      console.log('Redirecting to home...')
      navigate('/home')
    }
  }, [])

  function triggerNavbar () {
    dispatch(
      setAppState({
        key: 'navbarExpanded',
        value: !navbarExpanded
      })
    )
  }

  function performLogOut () {
    dispatch(setUser(null))
    dispatch(setCurrentMusic(null))
    musicState.soundObject?.howler?.stop()
    dispatch(setSoundObject(null))
  }


  return (
    <Grid
      w='full'
      h='full'
      gridTemplateColumns={'1fr'}
      gridTemplateRows={'70px 1fr'}
      gridColumnGap={'0px'}
      gridRowGap={'0px'}
      bg='#1b1f1f'
      overflow={'hidden'}
    >
      <GridItem
        w='full'
        h='full'
        alignContent={'center'}
        // borderBottom={'1px solid #2E4F4F'}
        px='4'
        pr='2'
        pos='relative'
      >
        <MusicComponent.SimpleAudioPlayer />
        <HStack w='full' justify={'space-between'}>
          <HStack w='max-content'>
            <SharedComponents.AppComponent.LazyImage
              src={adminProfile.photoUrl}
              h='40px'
              w='40px'
              borderRadius={'full'}
              objectFit={'cover'}
            />
            <VStack spacing={'0'} color='white' align={'flex-start'}>
              <Text fontSize={'12px'} fontWeight={'500'}>
                Your favorite,
              </Text>
              <Text fontSize={'14px'} fontWeight={'500'}>
                {adminProfile.name}
              </Text>
            </VStack>
          </HStack>
          <HStack justify={'flex-end'} w='max-content' h='full' py='2' px='4'>
            {!isMobile && (
              <HStack w='max-content' h='full' spacing={'4'}>
                <VStack
                  w='max-content'
                  h='full'
                  spacing={'0'}
                  alignItems={'flex-start'}
                  justify={'center'}
                  color='white'
                >
                  <Text fontSize={'12px'} fontWeight={'500'}>
                    Logged in as,
                  </Text>
                  <Text fontSize={'14px'} fontWeight={'500'}>
                    {user.name}
                  </Text>
                </VStack>
                <Box w='20px' />
                <SharedComponents.AppComponent.LogOutButton
                  onLogOut={performLogOut}
                  isUserApp={true}
                />
              </HStack>
            )}
            {isMobile && (
              <SharedComponents.AppComponent.LogOutButton
                onLogOut={performLogOut}
                isUserApp={true}
              />
            )}
          </HStack>
        </HStack>
      </GridItem>
      <GridItem w='full' h='full' overflow={'hidden'}>
        <Grid
          transition={'all 300ms'}
          gridTemplateColumns={navbarExpanded ? '240px 1fr' : '65px 1fr'}
          h='100%'
          pl='2'
        >
          <GridItem
            // borderRight={navbarExpanded ? '1px solid #2E4F4F' : 'none'}
            bg='black'
            boxShadow={
              !navbarExpanded ? '0px 0px 20px 0px rgba(19,51,133,0.2)' : 'none'
            }
            px={navbarExpanded ? '4' : '0'}
            mb='2'
            borderRadius={'lg'}
            overflow={'hidden'}
            py={navbarExpanded ? '3' : '0'}
          >
            <VStack w='full' h='full' justify={'space-between'}>
              <VStack
                w='full'
                h='max-content'
                spacing={navbarExpanded ? '2' : '0'}
              >
                {MainAppRoutes().routes.map(route => (
                  <SharedComponents.AppComponent.NavItem
                    key={`navitemKey_${route.path}`}
                    navbarExpanded={navbarExpanded}
                    isUserApp={true}
                    {...route}
                  />
                ))}
              </VStack>
              <SharedComponents.AppComponent.NavCollapseBtn
                onCollapseExpand={triggerNavbar}
                navbarExpanded={navbarExpanded}
                isUserApp={true}
              />
            </VStack>
          </GridItem>
          <GridItem
            w='full'
            py='5'
            pt='1'
            px='5'
            pl='7'
            h='full'
            overflow={'scroll'}
          >
            <Outlet />
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}
