import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack
} from '@chakra-ui/react'
import { FaPause, FaPlay, FaX } from 'react-icons/fa6'
import './components.css'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import SharedComponents, {
  FirestorePackageExport as FirestorePackage
} from 'shared-components'
import { Howl, Howler } from 'howler'
import { AnimatePresence, color, motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentMusic,
  setMusic,
  setSoundObject
} from '../../../redux/slices/musicSlice'
import * as FirebaseFunctions from 'firebase/functions'
import colorTheif from 'colorthief'
import { RiCollapseDiagonal2Line } from 'react-icons/ri'
import moment from 'moment'
import { FaCheckCircle } from 'react-icons/fa'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { setUserProps } from '../../../redux/slices/userSlice'
import { v4 as uuidv4 } from 'uuid'

const SimpleAudioPlayer = () => {
  const adminProfile = useSelector(state => state.app.adminProfile)
  const musicState = useSelector(state => state.music)
  const userState = useSelector(state => state.user)
  const subscriptionState = useSelector(state => state.subscription)
  const dispatch = useDispatch()
  const priceModalRef = useRef()
  const [availableSubscriptions, setAvailableSubscriptions] = useState()
  const {
    functions: firebaseContextFunctions,
    addDocument: firebaseAddDocument
  } = SharedComponents.Firebase.context()
  const [allowPlay, setAllowPlay] = useState(false)
  const [isCheckoutBusy, setIsCheckoutBusy] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const currentSound = useSelector(state => state.music.soundObject)
  const currentMusic = useSelector(state => state.music.current)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [showPaymentConfirmationModal, setShowPaymentConfirmationModal] =
    useState(false)
  const toast = useToast()
  const [sessionId, setSessionId] = useState()

  // After a successful transaction, we are confirming the purchase of the music,
  // via the URL params

  useEffect(() => {
    if (musicState.current) {
      performOperations()
    }
  }, [musicState.current])

  useEffect(() => {
    // if (currentSound?.isPlaying) {
    //   // We will record the stream of the music, every 7 seconds
    //   const streamInterval = setInterval(() => {
    //     const seek = Math.ceil(currentSound?.howler?.seek())
    //     console.log('Got the seek, now recording...')
    //     recordStream(seek) // You can modify the duration if needed
    //   }, 5000)
    //   return () => clearInterval(streamInterval)
    // }
  }, [currentSound?.isPlaying])

  async function performOperations () {
    setAllowPlay(false)
    const _plan = await checkMusicPlan(musicState.current)
    if (_plan.mode === 'play') {
      setAllowPlay(true)
      performPlay()
    } else {
      setAllowPlay(false)
      stopMusic({ userNotPaid: true })
    }
  }

  useEffect(() => {
    if (allowPlay && musicState.current) {
      setNameAnimation()
    }
  }, [allowPlay, musicState.current, expanded])

  async function checkMusicPlan (musicData) {
    if (
      (!musicData.priceId || musicData.priceId.length <= 0) &&
      !musicData.useAlbumPricingPlan
    ) {
      // The music has been set as "Free to stream" by the artist
      // This means the music also doest derive it's price from the associated album
      return {
        mode: 'play'
      }
    } else if (musicData.isPurchasedByUser) {
      return {
        mode: 'play'
      }
    } else {
      // Now first we get the PriceID of the music
      // Here, if `useAlbumPricingPlan` is true,
      // then we get the PriceID of the album
      // If false, we simply return the PriceId of the music

      // Now here we either use the Album's price ID(if exists), or return the PriceId of the single
      // item
      const _MusicPriceId = () => {
        if (
          musicData.album &&
          musicData.useAlbumPricingPlan &&
          musicData.album.priceId &&
          musicData.album.priceId.length > 0
        ) {
          // console.log('Using album monetization plan...')
          return musicData.album.priceId
        } else {
          // console.log('Using music monetization plan...')
          return musicData.priceId
        }
      }

      const _activeSubs = userState.subscriptions?.filter(
        x => x.status === 'active' && !x.ended_at
      )
      console.log('Active subs:', _activeSubs)

      if (_MusicPriceId()?.some(x => x.type === 'recurring')) {
        // Now we know that the music is part of a subscription
        console.log(
          'Music is part of a subscription. Printing music price IDs',
          _MusicPriceId()
        )

        const isUserSubscribed = _activeSubs?.some(x =>
          _MusicPriceId()?.some(({ id }) => x.plan.id === id)
        )
        if (isUserSubscribed)
          return {
            mode: 'play'
          }
      }

      // The user has not paid for the music
      // So we trigger the purchase modal
      const music_subs_orPrices = subscriptionState
        ?.filter(
          x => x.active && _MusicPriceId()?.some(({ id }) => x.id === id)
        )
        .map((item, index) => ({ ...item }))
      setAvailableSubscriptions(music_subs_orPrices)
      priceModalRef.current?.triggerModal()

      return {
        mode: 'unpaid'
      }
    }
  }

  function setNameAnimation () {
    const scrollContainer = document.getElementById('scroll-container')
    const scrollText = document.getElementById('scroll-text')
    if (scrollContainer && scrollText) {
      if (!expanded) {
        console.log('Setting animation again....')
        scrollContainer.classList.remove('expanded-text')
        const containerWidth = scrollContainer.offsetWidth
        const textWidth = scrollText.scrollWidth
        console.log(
          'Text width:',
          textWidth,
          ' and containerWidth:',
          containerWidth
        )
        if (textWidth < containerWidth) {
          scrollText.style.setProperty('animation', 'none')
        } else {
          scrollText.style.setProperty(
            'animation',
            'scroll 8s ease-in-out infinite'
          )
          scrollText.style.setProperty('--text-width', `${textWidth}px`)
        }
      } else {
        console.log('Removing animation...')
        scrollContainer.classList.add('expanded-text')
      }
    }
  }

  async function performPlay () {
    setSessionId(uuidv4())
    recordStreamOverview()

    // if (musicState.soundObject) {
    if (musicState.soundObject?.howler) {
      musicState.soundObject.howler?.stop()
      // dispatch(setCurrentMusic(null))
      dispatch(setSoundObject(null))
    }

    const sound = new Howl({
      src: musicState.current.baseUrl,
      onload: () =>
        dispatch(
          setSoundObject({
            isPlaying: true,
            isLoaded: true
          })
        ),
      onend: () =>
        dispatch(
          setSoundObject({
            isPlaying: false
          })
        )
    })
    const soundId = sound.play()
    dispatch(
      setSoundObject({
        howler: sound,
        id: soundId
      })
    )

    // recordStream()
  }

  function playPause () {
    // setSoundObj(prev => ({ ...prev, isPlaying: !prev.isPlaying }))
    const seek = Math.ceil(currentSound?.howler?.seek())
    recordStream(seek)
    dispatch(
      setSoundObject({
        isPlaying: !currentSound.isPlaying
      })
    )
    if (currentSound.isPlaying) currentSound.howler?.pause()
    else {
      currentSound.howler?.play(currentSound.id)
    }
  }

  async function recordStreamOverview () {
    await firebaseAddDocument({
      path: `profile/${adminProfile.id}/songs/${currentMusic.id}/streams-overview-location/${userState.ipinfo.country}-${userState.ipinfo.city}`,
      data: {
        city: userState.ipinfo.city,
        country: userState.ipinfo.country,
        streamCount: FirestorePackage.increment(1)
      },
      ignoreStateUpdate: true,
      allowMerge: true
    })
    await firebaseAddDocument({
      path: `profile/${adminProfile.id}/songs/${currentMusic.id}/streams-overview-listeners/${userState.id}`,
      data: {
        streamCount: FirestorePackage.increment(1),
        name: userState.name,
        id: userState.id,
        email: userState.email
      },
      ignoreStateUpdate: true,
      allowMerge: true
    })
  }

  async function recordStream (duration) {
    try {
      // console.log('Recoridng stream. Customer ID:', userState)
      await firebaseAddDocument({
        path: `profile/${adminProfile.id}/songs/${currentMusic.id}/streams/${sessionId}`,
        data: {
          userId: userState.id,
          userName: userState.name,
          customerId: userState.customerId,
          duration: duration,
          date: Date.now(),
          ipinfo: userState.ipinfo
        },
        ignoreStateUpdate: true
      })
    } catch (ex) {
      console.log('Could not add stream record:', ex)
    }
  }

  function setSelectedSubscription (id) {
    setAvailableSubscriptions(prev => {
      const _shallow = [...prev]
      const updated = _shallow.map(item => {
        if (item.id === id) {
          return {
            ...item,
            isSelected: item.isSelected ? !item.isSelected : true
          }
        } else {
          return { ...item, isSelected: false }
        }
      })
      // const _specificIdx = _shallow.findIndex(x => x.id === id)
      // _shallow[_specificIdx] = {
      //   ..._shallow[_specificIdx],
      //   isSelected: _shallow[_specificIdx].isSelected
      //     ? !_shallow[_specificIdx].isSelected
      //     : true
      // }
      return updated
    })
  }

  async function generatePaymentLink () {
    setIsCheckoutBusy(true)
    const priceObj = availableSubscriptions.find(x => x.isSelected)
    const checkout = FirebaseFunctions.httpsCallable(
      firebaseContextFunctions,
      'createCheckout'
    )
    try {
      const data = {
        priceId: priceObj.id,
        customerId: userState.customerId,
        mode: priceObj.recurring?.interval ? 'subscription' : 'payment',
        redirectUrl: `${
          window.location.href
        }?sessionId={CHECKOUT_SESSION_ID}&songId=${
          currentMusic.id
        }&paymentMode=${
          priceObj.recurring?.interval ? 'subscription' : 'payment'
        }`,
        songId: currentMusic.id,
        stripeAccountId: adminProfile.stripeAccountId
      }
      console.log('Generating checkout session with data:', data)
      const result = await checkout(data)
      if (result.data.url) {
        console.log('Checkout session created:', result.data.id)
        window.open(result.data.url, '_self')
      } else {
        console.log('Could not generate a checkout session.', result)
      }

      // dispatch(setAllSubscriptions(result.data))
      // dispatch(setAllMusic(allSongs))
    } catch (ex) {
      console.warn('CloudFunction error:', ex)
    }
    setIsCheckoutBusy(false)
  }
  useEffect(() => {
    const sessionId = searchParams.get('sessionId')
    const songId = searchParams.get('songId')
    const paymentMode = searchParams.get('paymentMode')

    if (sessionId && songId) finalizePurchase(sessionId, songId, paymentMode)
  }, [])

  async function finalizePurchase (sessionId, songId, paymentMode) {
    setShowPaymentConfirmationModal(true)

    if (paymentMode === 'subscription') {
      const getCustomer = FirebaseFunctions.httpsCallable(
        firebaseContextFunctions,
        'retrieveCustomer'
      )

      try {
        const result = await getCustomer({
          id: userState.customerId
        })
        console.log('Customer result is:', result.data)
        if (result.data.subscriptions) {
          toast({
            title: 'Your subscription is active!',
            description: 'You can now stream the music/album',
            status: 'success',
            isClosable: true
          })
          dispatch(
            setUserProps({
              key: 'subscriptions',
              value: result.data.subscriptions
            })
          )
          navigate(location.pathname)
        } else {
          toast({
            title: 'Something went wrong!',
            description: "We couldn't process your payment at the moment.",
            status: 'error',
            isClosable: true
          })
        }
      } catch (ex) {
        toast({
          title: 'Something went wrong!',
          description: "We couldn't process your payment at the moment.",
          status: 'success',
          isClosable: true
        })
        console.log('CloudFunction error:', ex)
      }
    } else if (paymentMode === 'payment') {
      const confirmMusicPurchase = FirebaseFunctions.httpsCallable(
        firebaseContextFunctions,
        'confirmMusicPurchase'
      )
      try {
        const result = await confirmMusicPurchase({
          sessionId,
          songId,
          userId: userState.id,
          adminId: adminProfile.id
        })
        // console.log('Finale result is:', result.data)
        if (result.data.success) {
          dispatch(setMusic({ id: songId, isPurchasedByUser: true }))
          toast({
            title: "You're all set!",
            description: 'You can now stream the music/album',
            status: 'success',
            isClosable: true
          })
          navigate(location.pathname)
        }
      } catch (ex) {
        toast({
          title: 'Something went wrong!',
          description: "We couldn't process your payment at the moment.",
          status: 'success',
          isClosable: true
        })
        console.warn('CloudFunction error:', ex)
      }
    }
    setShowPaymentConfirmationModal(false)
  }

  function stopMusic ({ userNotPaid }) {
    const seek = Math.ceil(currentSound?.howler?.seek())
    !userNotPaid && recordStream(seek)
    setAllowPlay(false)
    musicState.soundObject?.howler?.stop()
    if (!userNotPaid) dispatch(setCurrentMusic(null))
    dispatch(setSoundObject(null))
  }

  return (
    <AnimatePresence>
      {/* The expanded player */}
      {allowPlay && expanded && (
        <motion.div
          key='music_player_expanded'
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: 'max-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: '999'
          }}
          initial={{ transform: 'scale(0.7)', opacity: 0, top: '-50px' }}
          animate={{ transform: 'scale(1)', opacity: 1, top: '15px' }}
          exit={{ transform: 'scale(0.7)', opacity: 0, top: '-50px' }}
        >
          <Box pos='relative'>
            <VStack
              className='music-player-container'
              transition={'all 300ms'}
              w='300px'
              bg={'black'}
              boxShadow={'0px 5px 20px 0px rgba(255,255,255,0.1)'}
              borderRadius={'10px'}
              _hover={{
                boxShadow: '0px 0px 10px 0px rgba(19,51,133,0.6)'
                // h: '100px',
                // borderRadius: 'md'
              }}
              alignItems={'center'}
              pos='relative'
              overflow={'hidden'}
              px={'1'}
              py={'1'}
              pb='4'
            >
              <Box
                pos='absolute'
                top='0'
                bottom={'0'}
                left='0'
                right={'0'}
                filter='blur(5px)'
                bgImage={musicState.current?.imageUrl}
                bgSize={'cover'}
                bgPos={'center'}
                transform={'scale(1.05)'}
                zIndex={'0'}
              />
              <Box
                pos='absolute'
                top='0'
                bottom={'0'}
                left='0'
                right={'0'}
                // bg='#1b1f1f70'
                bgGradient='linear(to-t, #1b1f1f,  transparent)'
                zIndex={'0'}
              />
              <SharedComponents.AppComponent.LazyImage
                h={'200px'}
                w={'300px'}
                src={musicState.current?.imageUrl}
                borderRadius={'10px'}
                objectFit={'cover'}
                zIndex={'2'}
                pos='relative'
              />
              <VStack
                role='group'
                spacing={'3'}
                zIndex={'2'}
                px='3'
                align={'flex-start'}
                w='full'
                flexWrap={'wrap'}
              >
                <Text color='white' fontSize={'18px'} fontWeight={'bold'}>
                  {musicState.current?.name}
                </Text>
                <Grid
                  w='full'
                  gridTemplateColumns={'45px auto'}
                  gridColumnGap={'5'}
                >
                  <IconButton
                    icon={
                      musicState.soundObject?.isPlaying ? (
                        <FaPause size='15' />
                      ) : (
                        <FaPlay size='15' />
                      )
                    }
                    h='45px'
                    w='45px'
                    p='0'
                    size='sm'
                    colorScheme={'black'}
                    borderRadius={'35px'}
                    bg={'white'}
                    color={'black'}
                    _hover={{
                      bg: 'black',
                      color: 'white'
                    }}
                    isLoading={!musicState.soundObject?.isLoaded}
                    onClick={playPause}
                  />
                  <MusicProgressBar />
                </Grid>
              </VStack>
            </VStack>
            <VStack pos='absolute' top='10px' right='-20px' zIndex={'99'}>
              <IconButton
                variant='ghost'
                h='40px'
                w='30px'
                fontSize={'13px'}
                icon={<FaX />}
                borderRadius={'full'}
                bg='white'
                color='black'
                _hover={{
                  bg: 'gray.800',
                  color: 'white'
                }}
                borderWidth={'1px'}
                borderColor={'white'}
                onClick={stopMusic}
                boxShadow={'0px 0px 20px 0px rgba(0,0,0,0.6)'}
              />
              <IconButton
                variant='ghost'
                h='40px'
                w='30px'
                fontSize={'18px'}
                icon={<RiCollapseDiagonal2Line />}
                borderRadius={'full'}
                bg='white'
                color='black'
                _hover={{
                  bg: 'gray.800',
                  color: 'white'
                }}
                borderWidth={'1px'}
                borderColor={'white'}
                onClick={() => setExpanded(false)}
                boxShadow={'0px 0px 20px 0px rgba(0,0,0,0.6)'}
              />
            </VStack>
          </Box>
        </motion.div>
      )}

      {/* The collapsed player */}
      {allowPlay && !expanded && (
        <motion.div
          key='music_player_collapsed'
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            width: 'max-content',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: '999'
          }}
          initial={{ transform: 'scale(0.7)', opacity: 0, top: '-50px' }}
          animate={{ transform: 'scale(1)', opacity: 1, top: '15px' }}
          exit={{ transform: 'scale(0.7)', opacity: 0, top: '-50px' }}
        >
          <HStack
            className='music-player-container'
            transition={'all 300ms'}
            w='max-content'
            bg={'black'}
            boxShadow={'0px 5px 10px 0px rgba(19,51,133,0.4)'}
            borderRadius={expanded ? '8px' : '40px'}
            _hover={{
              boxShadow: '0px 0px 10px 0px rgba(19,51,133,0.6)'
              // h: '100px',
              // borderRadius: 'md'
            }}
            h={'45px'}
            alignItems={'center'}
            pos='relative'
            overflow={'hidden'}
            px={'1'}
            py={'1'}
          >
            <HStack
              role='group'
              borderRadius={'full'}
              spacing={'3'}
              zIndex={'2'}
              px='0'
              py='0'
            >
              <SharedComponents.AppComponent.LazyImage
                h={'35px'}
                w={'35px'}
                src={musicState.current?.imageUrl}
                borderRadius={'35px'}
                objectFit={'cover'}
              />

              <IconButton
                icon={
                  musicState.soundObject?.isPlaying ? (
                    <FaPause size='15' />
                  ) : (
                    <FaPlay size='15' />
                  )
                }
                h='35px'
                w='35px'
                p='0'
                size='sm'
                colorScheme={'black'}
                borderRadius={'35px'}
                bg={'black'}
                color={'white'}
                _hover={{
                  bg: 'white',
                  color: 'black'
                }}
                isLoading={!musicState.soundObject?.isLoaded}
                onClick={playPause}
              />
              <Tooltip
                hasArrow
                placement='bottom'
                borderRadius={'md'}
                label='Click to Expand'
                bg='white'
                px='3'
                py='2'
                color='black'
                mt='10px'
              >
                <Box
                  transition={'all 300ms'}
                  pos='relative'
                  w={'150px'}
                  color='white'
                  h='20px'
                  overflow={'hidden'}
                  id='scroll-container'
                  cursor={'pointer'}
                  onClick={() => setExpanded(prev => !prev)}
                >
                  <Box id={'scroll-text'} pos='absolute'>
                    <Text
                      h='max-content'
                      fontSize={'14px'}
                      fontWeight={'bold'}
                      style={{
                        textWrap: 'nowrap'
                      }}
                    >
                      {musicState.current?.name}
                    </Text>
                  </Box>
                </Box>
              </Tooltip>
            </HStack>
            {expanded && (
              <motion.div
                initial={{
                  opacity: 0
                }}
                animate={{
                  opacity: 1
                }}
              >
                <Box
                  pos='absolute'
                  top='0'
                  bottom={'0'}
                  left='0'
                  right={'0'}
                  filter='blur(5px)'
                  bgImage={musicState.current?.imageUrl}
                  bgSize={'cover'}
                  bgPos={'center'}
                  transform={'scale(1.05)'}
                  zIndex={'0'}
                />
                <Box
                  pos='absolute'
                  top='0'
                  bottom={'0'}
                  left='0'
                  right={'0'}
                  // bg='#1b1f1f70'
                  bgGradient='linear(to-t, #1b1f1f,  transparent)'
                  zIndex={'0'}
                />
              </motion.div>
            )}
            <IconButton
              size='md'
              h='34px'
              w='35px'
              icon={<FaX size='12px' />}
              borderRadius={'45px'}
              bg='black'
              color='white'
              _hover={{
                bg: 'gray.800',
                color: 'white'
              }}
              onClick={stopMusic}
            />
          </HStack>
        </motion.div>
      )}
      <Modal bg='#1B1F1F' isOpen={showPaymentConfirmationModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody bg='#1B1F1F' border='1px solid green' py='4'>
            <VStack
              justify={'center'}
              align={'center'}
              w='full'
              h='max-content'
              color='white'
            >
              <Text fontSize={'18px'} fontWeight={'semibold'}>
                Confirming your purchase, please wait...
              </Text>
              <Spinner size={'lg'} color='white' />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <SharedComponents.AppComponent.Modal
        key={'price_modal_home'}
        ref={priceModalRef}
        // onProceed={generatePaymentLink}
        size='md'
        proceedBtnLabel='Proceed To Pay'
        isUserApp={true}
        isProceedDisabled={availableSubscriptions?.every(
          x => x.isSelected !== true
        )}
        onProceed={generatePaymentLink}
        isProceedBusy={isCheckoutBusy}
        onClose={() => stopMusic({ userNotPaid: true })}
      >
        <Text
          fontSize={'26px'}
          fontWeight={'bold'}
          fontFamily={'roboto'}
          lineHeight={'2rem'}
        >
          Listen to "{musicState.current?.name}"
        </Text>
        <Text fontSize={'16px'} fontWeight={'semibold'}>
          Select a plan
        </Text>
        {availableSubscriptions?.map(sub_single => (
          <Grid
            key={`player_payment_modal_planItem_${sub_single.id}`}
            cursor={'pointer'}
            transition={'all 300ms'}
            gridTemplateRows={'repeat(2, 1fr)'}
            gridTemplateColumns={'1fr 130px'}
            w='full'
            bg={sub_single.isSelected ? 'green' : 'black'}
            border='1px solid black'
            // borderLeftWidth={sub_single.isSelected ? '5px' : '1px'}
            borderRadius={'md'}
            px='3'
            py='2'
            _hover={{
              border: '1px solid green',
              transform: 'scale(1.03)'
            }}
            onClick={() => setSelectedSubscription(sub_single.id)}
          >
            <GridItem gridArea={'1 / 1 / 2 / 2'}>
              <HStack>
                {/* {sub_single.isSelected && <FaCheckCircle color='green' />} */}
                <Text fontSize={'16px'} fontWeight={'bold'}>
                  {sub_single.product.name}
                </Text>
              </HStack>
            </GridItem>
            <GridItem gridArea={'2 / 1 / 3 / 2'}>
              <Text textTransform={'capitalize'}>
                {sub_single.recurring?.interval
                  ? `${sub_single.recurring?.interval}ly Subscription`
                  : 'One-Time Fee'}
              </Text>
            </GridItem>
            <GridItem
              gridArea={'1 / 2 / 3 / 3'}
              alignContent={'center'}
              textAlign={'right'}
            >
              ${sub_single.unit_amount / 100}
              {sub_single.recurring?.interval
                ? `/${sub_single.recurring?.interval}`
                : ''}
            </GridItem>
          </Grid>
        ))}
      </SharedComponents.AppComponent.Modal>
    </AnimatePresence>
  )
}

const MusicProgressBar = ({ musicImageUrl }) => {
  const currentSound = useSelector(state => state.music.soundObject)
  // const currentHowler = useSelector(state => state.music.soundObject?.howler)
  const currentMusic = useSelector(state => state.music.current)
  const userState = useSelector(state => state.user)
  const [progress, setProgress] = useState(0)
  const [dominantColor, setDominantColor] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const firebaseContext = SharedComponents.Firebase.context()

  useEffect(() => {
    const duration = currentSound?.howler?.duration()
    if (currentSound?.isPlaying && duration > 0) {
      const progressInterval = setInterval(() => {
        if (!isDragging) {
          const seek = currentSound?.howler?.seek()
          setProgress(seek)
        }
      }, 1000)

      return () => clearInterval(progressInterval)
    }
  }, [
    progress,
    currentSound?.howler?.duration(),
    currentSound?.isPlaying,
    isDragging
  ])

  useEffect(() => {
    getDominantColor()
  }, [currentMusic])

  function getDominantColor () {
    if (currentMusic) {
      const ctf = new colorTheif()
      const img = document.createElement('img')
      img.crossOrigin = 'Anonymous'
      img.src = currentMusic?.imageUrl
      img.addEventListener('load', ev => {
        const colorRes = ctf.getColor(img)
        setDominantColor(`rgba(${colorRes.join(',')},0.55)`)
      })
    }
  }

  function durationSeekText () {
    const _duration = currentSound?.howler?.duration()
    return `${moment.utc(progress * 1000).format('mm:ss')} / ${moment
      .utc(_duration * 1000)
      .format('mm:ss')}`
  }

  return (
    <VStack spacing={'0.5'} align={'flex-start'} justify={'center'} pt='2'>
      <Slider
        colorScheme='pink'
        defaultValue={0}
        min={0}
        max={currentSound?.howler?.duration()}
        value={progress}
        onChange={val => {
          setIsDragging(true) // User is dragging
          setProgress(val) // Update progress immediately while dragging
        }}
        onChangeEnd={val => {
          setIsDragging(false) // User stopped dragging
          currentSound?.howler?.seek(val, currentSound.id) // Seek to new position in the track
        }}
      >
        <SliderTrack>
          <SliderFilledTrack bg={dominantColor || 'white'} />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <Text color='white' fontSize={'12px'}>
        {durationSeekText()}
      </Text>
    </VStack>
  )
}

export const MusicComponent = {
  SimpleAudioPlayer
}
